<template>
  <div>
    <div v-if="addActiveDisable">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>单品活动</el-breadcrumb-item>
          <el-breadcrumb-item>{{ mybreadcrumb }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="padding: 5px">
          <i class="el-icon-close icon" @click="closeClick"></i>
        </div>
      </div>
      <div class="tableInput">
        <span>活动商品：</span>
        <el-input
          v-model="productInp"
          @focus="productFocus"
          style="width: 15%"
          placeholder="点击选择商品"
        ></el-input>
        <el-input
          v-model="QueryContent"
          placeholder="请输入内容"
          style="width: 15%"
        ></el-input>
        <el-button type="success" @click="tabSearch"
          ><i class="el-icon-search"></i>查询</el-button
        >
      </div>
      <div class="tableBtn">
        <el-button type="primary" @click="addActiveDisable = !addActiveDisable"
          ><i class="el-icon-plus"></i> 发布活动</el-button
        >
        <el-button type="danger" @click="batchDelete"
          ><i class="el-icon-delete"></i> 批量删除</el-button
        >
      </div>
      <div class="table">
        <!-- 表格 -->
        <div style="padding: 10px 0">
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%; border: 1px solid #e5e5e5"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" align="center"> </el-table-column>
            <el-table-column
              prop="create_time"
              sortable
              label="创建日期"
              align="center"
            >
            </el-table-column>
            <el-table-column label="活动商品" align="center">
              <template slot-scope="scope">
                <div v-for="(item, key) in scope.row.product_arr" :key="key">
                  {{ item.product_name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="activity_type" label="类型" align="center">
            </el-table-column>
            <el-table-column
              prop="activity_name"
              label="活动名称"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="activity_star_day"
              sortable
              label="开始日期"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="activity_end__day"
              sortable
              label="结束日期"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="activity_inventory"
              sortable
              label="库存"
              align="center"
            >
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <i
                  class="el-icon-edit poiner"
                  @click="handleEdit(scope.row)"
                ></i>
                <span style="margin: 0 10px"></span>
                <i
                  class="el-icon-delete poiner"
                  @click="handleDelete(scope.row)"
                ></i>
              </template>
            </el-table-column>
          </el-table>
          <div class="tableBottom">
            <span style=""
              >显示第&nbsp;{{
                tableData.length == 0 ? 0 : (page - 1) * length + 1
              }}&nbsp;至&nbsp;{{
                (page - 1) * length + tableData.length
              }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
            >

            <div
              style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
              "
            >
              <el-button @click="homePage">首页</el-button>
              <el-button @click="upPage">上页</el-button>
              <el-pagination
                background
                layout=" pager,slot"
                :total="tableFinshNum"
                :current-page="page"
                @current-change="currentChange"
              ></el-pagination>
              <el-button @click="nextPage">下页</el-button>
              <el-button @click="endPage">末页</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!addActiveDisable">
      <transition name="fade">
        <add-active
          @close="closeAdd"
          :breadcrumb="mybreadcrumb"
          :data="data"
        ></add-active>
      </transition>
    </div>
    <AddProduct
      :productShow="productShow"
      @addConfirm="addConfirm"
      @handleClose="handleClose"
    />
  </div>
</template>
<script>
import { urlList } from "./child/data";
import qs from "qs";
import AddProduct from "../../../../addProduct/addProduct.vue";
export default {
  props: ["breadcrumb"],
  components: {
    addActive: () => import("./child/singleItem_add.vue"),
    AddProduct,
  },
  data() {
    return {
      dateTime: { date1: "", date2: "" },
      QueryContent: "",
      tableData: [],
      isResult: false,
      tableFinshNum: 0,
      addActiveDisable: true,
      page: 1,
      length: 10,
      baseUrl: "",
      productInp: "",
      productArr: [],
      productShow: false,
      changeArr: [],
      data: "",
    };
  },
  mounted() {
    if (this.tableData.length == 0) {
      this.isResult = false;
    } else {
      this.isResult = true;
      this.tableFinshNum = this.tableData.length;
    }
    urlList.forEach((item) => {
      if (item.title == this.$props.breadcrumb) {
        this.baseUrl = item.url;
      }
    });
    this.getTabList();
  },
  computed: {
    mybreadcrumb() {
      return this.breadcrumb;
    },
  },
  methods: {
    closeAdd(data) {
      this.addActiveDisable = data;
      this.getTabList();
      this.productShow = false;
      this.data = "";
    },
    getTabList() {
      this.$http
        .post(
          this.baseUrl + "/lst",
          qs.stringify({
            currentPageTotal: this.length,
            currentPage: this.page,
          })
        )
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    productFocus() {
      this.productShow = true;
    },
    handleClose() {
      this.productShow = false;
    },
    addConfirm(val) {
      this.productArr = [val[val.length - 1]];
      this.productInp = val[val.length - 1].product_name;
      this.productShow = false;
    },
    tabSearch() {
      this.$http
        .post(
          `${this.baseUrl}/search`,
          qs.stringify({
            currentPage: this.page,
            currentPageTotal: this.length,
            search: this.QueryContent,
            product_arr: JSON.stringify(this.productArr),
          })
        )
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    handleEdit(row) {
      this.data = row;
      this.addActiveDisable = false;
    },
    handleDelete(row) {
      this.$confirm("确定删除文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post(`${this.baseUrl}/del`, qs.stringify({ id: row.id }))
            .then((res) => {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.getTabList();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSelectionChange(arr) {
      this.changeArr = arr;
    },
    batchDelete() {
      if (this.changeArr.length > 0) {
        this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            const arr = [];
            this.changeArr.forEach((item) => {
              arr.push(item.id);
            });
            this.$http
              .post(`${this.baseUrl}/bacthdel`, {
                idArr: arr,
              })
              .then((res) => {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.getTabList();
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    closeClick() {
      this.$emit("closeClick");
    },
    currentChange(page) {
      this.page = page;
    },
    homePage() {
      this.page = 1;
    },
    upPage() {
      if (this.page <= 1) return;
      this.page -= 1;
    },
    nextPage() {
      if (this.page >= Math.ceil(this.tableFinshNum / this.length)) return;
      this.page++;
    },
    endPage() {
      this.page = Math.ceil(this.tableFinshNum / this.length);
    },
  },
  watch: {},
};
</script>
<style scoped lang="less">
.icon {
  cursor: pointer;
}

.icon:hover {
  color: red;
}

.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableInput {
  height: 31px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
}

.tableBtn {
  padding: 5px 20px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 14px;
}

.table {
  padding: 20px;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

.tableStatus {
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  padding: 3px;
  font-weight: bold;
}
</style>
<style lang="less">
.el-select {
  margin: 0 5px !important;
  width: 8% !important;
}
</style>
